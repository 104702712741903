import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Pre Open WOD 5`}</strong></p>
    <p>{`3:00 AMRAP of:`}</p>
    <p>{`3-Power Snatch (RX 95/65, Scaled 75/55, Masters 65/45)`}</p>
    <p>{`3-T2B (Scaled & Masters Hanging Leg Raise, feet must be above parallel)`}</p>
    <p>{`6-Power Snatch`}</p>
    <p>{`6-T2B`}</p>
    <p>{`9-Power Snatch`}</p>
    <p>{`9-T2B`}</p>
    <p>{`Etc.`}</p>
    <p>{`Rest 1:00 then,`}</p>
    <p>{`3:00 AMRAP of:`}</p>
    <p>{`3-OHS`}</p>
    <p>{`3-Bar Facing Burpee`}</p>
    <p>{`6-OHS`}</p>
    <p>{`6-Bar Facing Burpee`}</p>
    <p>{`9-OHS`}</p>
    <p>{`9-Bar Facing Burpee`}</p>
    <p>{`Etc.`}</p>
    <p>{`Rest 1:00 then,`}</p>
    <p>{`3:00 AMRAP of:`}</p>
    <p>{`3-Full Snatch`}</p>
    <p>{`3-Bar Muscle Ups`}</p>
    <p>{`6-Full Snatch`}</p>
    <p>{`6-Bar Muscle Ups (Scaled & Masters Men=Bar at Eye Height, Women=Bar at
Chest Height)`}</p>
    <p>{`9-Full Snatch`}</p>
    <p>{`9-Bar Muscle Ups`}</p>
    <p>{`Etc.`}</p>
    <p>{`Score=Total Reps.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      